import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

axios.defaults.baseURL = 'https://staging.api.undp.hybrain.co';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
  if (
    config.url != `/api/grade-types` &&
    config.url != `/api/platform-types` &&
    config.url != `/api/entity-types` &&
    config.url != `/api/organization-types` &&
    // config.url != `/api/document-types` &&
    config.url != `/api/display-types` &&
    config.url != `/api/data-types` &&
    config.url != `/api/item-types` &&
    config.url != `/api/machinery-types` &&
    config.url != `/api/staff-positions` &&
    config.url != `/api/product-types` &&
    config.url != `/api/item-categories/materials` &&
    config.url != `/api/item-categories/products` &&
    config.url != `/api/vetting-status` &&
    config.url != `/api/beneficiaries` &&
    config.url != `/api/auth/check-email` &&
    config.url != `/api/auth/check-password` &&
    config.url != `/api/auth/forgot-password` &&
    config.url != `/api/auth/reset-password` &&
    config.url != `/api/auth/login` &&
    config.url != `/api/auth/register`
  ) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response.data;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
