import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const User = lazy(() => import('./pages/user/User'));
const Login = lazy(() => import('./pages/Login'));
const Admin = lazy(() => import('./pages/admin/Admin'));

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/">
            <User />
          </Route>
          
          {/* <Route exact path="/admin">
            <Admin />
          </Route>
          <Route path="/login" component={Login}/> */}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes;