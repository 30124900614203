import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Routes from './Routes';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Roboto'
  },
}));

// import './scss/main.scss';

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Routes />
    </div>
  );
}

export default App;
